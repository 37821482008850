import * as prismic from "@prismicio/client";

const API_ENDPOINT = import.meta.env.ASTRO_PUBLIC_PRISMIC_URL;
const Client = prismic.createClient(API_ENDPOINT);

const linkResolver = (doc) => {
  // console.log(doc.url)
  // console.log(doc.url.slice(8))
  // try {
	// return doc.url.slice(8);
  // } catch (error) {
  //     return '/';
  // }
  return doc.url;
}

const serializer = {
  image: ({ node }) => {
    const linkUrl = node.linkTo ? linkResolver(node.linkTo) : null
    const linkTarget =
      node.linkTo && node.linkTo.target
        ? `target="${node.linkTo.target}" rel="noopener"`
        : ''
    const wrapperClassList = [node.label || '', 'block-img']
    const img = `<img src="${node.url}" alt="${
      node.alt ? node.alt : ''
    }" copyright="${node.copyright ? node.copyright : ''}" />`

    return `
        <p class="${wrapperClassList.join(' ')}">
          ${linkUrl ? `<a ${linkTarget} href="${linkUrl}" rel="prefetch">${img}</a>` : img}
        </p>
      `
  },
  hyperlink: ({ node, children }) => {
    const target = node.data.target
      ? `target="${node.data.target}" rel="noopener"`
      : ''
    const url = linkResolver(node.data)
    return `<a rel="prefetch" ${target} href="${url}">${children}</a>`
  },
}

const blogSerializer = {
  image: ({ node }) => {
    const linkUrl = node.linkTo ? linkResolver(node.linkTo) : null
    const linkTarget =
      node.linkTo && node.linkTo.target
        ? `target="${node.linkTo.target}" rel="noopener"`
        : ''
    const wrapperClassList = [node.label || 'block-img']
    const regex = /(w\=(\d+)\&)|(h\=(\d+)\&)/g;
    const imgSrcUrl = node.url.replaceAll(regex, "");

    const img = `<img src="${imgSrcUrl}&w=1000" alt="${
      node.alt ? node.alt : ''
    }" width="1000" height="${1000/node.dimensions.width * node.dimensions.height}" copyright="${node.copyright ? node.copyright : ''}" />`

    return `
        <p class="${wrapperClassList.join(' ')}">
          ${linkUrl ? `<a ${linkTarget} href="${linkUrl}" rel="prefetch">${img}</a>` : img}
        </p>
      `
  },
  hyperlink: ({ node, children }) => {
    const target = node.data.target
      ? `target="${node.data.target}" rel="noopener"`
      : ''
    const url = linkResolver(node.data)
    return `<a rel="prefetch" ${target} href="${url}">${children}</a>`
  },
}

export function getAllCategories(tag) {
	return Client.getByTag(tag);
}
export function getAllItemsByType(type, ...args) {
	return Client.getAllByType(type, ...args);
}

export function asHTML(text) {
	return prismic.asHTML(text, { linkResolver, serializer });
}

export function asBlogHTML(text) {
	return prismic.asHTML(text, { linkResolver, serializer:blogSerializer });
}

export function asText(text) {
	return prismic.asText(text);
}