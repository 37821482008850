<script>
  import { asText } from "src/prismic";
  import { navbarColor } from "@stores/NavbarStore";
  import { hideOnClickOutside } from "src/utils";

  export let slug, name, item, currentPage, linkCol, linkColCurr, openStore;

  $: dropdownBg = !$navbarColor
    ? "bg-slate-900 border-slate-700"
    : "bg-slate-700 border-slate-500";
  $: dropdownLinkCol = !$navbarColor
    ? "text-white hover:bg-slate-700 hover:text-slate-200"
    : "text-white hover:bg-slate-500 hover:text-slate-50";

  function runMenuClick() {
    const menu = document.querySelector(`.hs-dropdown-${slug}-menu`);
    const menuButton = document.querySelector(`#hs-mega-menu-basic-dr-${slug}`);
    const parentLink = document.querySelector(`.${slug}-parent-link`);
    openStore.set(!$openStore);

    // Toggle the aria-expanded attribute of MenuContainer
    menuButton.setAttribute("aria-expanded", $openStore ? "true" : "false");

    if ($openStore && innerWidth >= 768) {
      const navbarContainer = document.querySelector("#navbar-image-1");
      // Get the position of the parent link and the navbar container
      const parentLinkPos = parentLink.getBoundingClientRect();
      const navbarContainerPos = navbarContainer.getBoundingClientRect();

      // Set the position of the dropdown to the left of the parent link and the bottom of the navbar container using translate
      menu.style.transform = `translate(${parentLinkPos.left}px, ${navbarContainerPos.bottom}px)`;
    }

    hideOnClickOutside(`.${slug}-parent-link`, openStore);
  }
</script>

<div class={`relative ${slug}-parent-link`}>
  <button
    id={`hs-mega-menu-basic-dr-${slug}`}
    type="button"
    class={`flex w-full items-center font-medium ${
      currentPage == asText(item.data.nav[0].primary.link)
        ? `linkColCurr ${linkColCurr}`
        : `linkCol ${linkCol}`
    }`}
    aria-expanded="false"
    aria-label={`Toggle ${slug} menu`}
    on:click={runMenuClick}
  >
    {name}
    <svg
      class={`ml-2 h-2.5 w-2.5 ${
        currentPage == asText(item.data.nav[0].primary.link)
          ? `linkColCurr ${linkColCurr}`
          : `linkCol ${linkCol}`
      }`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </button>
  <div
    class={`hs-dropdown-${slug}-menu top-full ${!$openStore && "hidden"} z-10 mt-2 ${dropdownBg} rounded-lg p-2 transition-[opacity,margin] duration-[0.1ms] ${$openStore ? "opacity-100" : "opacity-0"} md:mt-0 md:w-48 md:border md:shadow-md md:duration-[150ms] md:fixed md:left-0 md:top-0 md:margin-0`}
    style=""
  >
    {#each item.data.nav[0].items as sub_item}
      <a
        rel="prefetch"
        class={`flex items-center gap-3 rounded-md px-3 py-2 text-sm ${dropdownLinkCol} focus:ring-2 focus:ring-blue-500`}
        href={`${asText(sub_item.sub_nav_link)}`}
      >
        {#if sub_item.sub_nav_icon.length}
          <span class="inline-flex items-center justify-center w-4 h-4">
            <iconify-icon class="text-[1rem]" icon={asText(sub_item.sub_nav_icon)} />
          </span>
        {/if}
        {asText(sub_item.sub_nav_link_label)}
      </a>
    {/each}
  </div>
</div>
