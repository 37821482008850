<script>
  import { asText } from "src/prismic";
  import { moreOpen, navbarColor, navbarOpen, servicesOpen } from "@stores/NavbarStore";

  import LogoColor from "@icons/LogoColor.svelte";
  import LogoWhite from "@icons/LogoWhite.svelte";
  import { onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import DropdownNavItem from "./DropdownNavItem.svelte";

  export let navigation;
  export let currentPage;

  let loaded = false;
  $: innerWidth = 0;
  $: if (innerWidth) {
    servicesOpen.set(false);
    moreOpen.set(false);
  }

  $: linkCol = !$navbarColor
    ? "text-white hover:text-slate-300"
    : "text-slate-900 hover:text-slate-500";
  $: linkColCurr = !$navbarColor ? "text-blue-300" : "text-blue-500";
  $: bg = !$navbarColor ? "bg-slate-800" : "bg-slate-50";
  $: navToggleColor = !$navbarColor
    ? "border-slate-700 bg-slate-900 text-white hover:bg-slate-800 hover:text-white focus:ring-offset-slate-800"
    : "border-slate-300 bg-slate-100 text-slate-900 hover:bg-slate-200 hover:text-slate-900 focus:ring-offset-slate-200";

  onMount(() => {
    const themeSwitcher = (color) =>
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", color);

    const header = document.querySelector("header");
    const options = {
      root: null,
      rootMargin: `${header.offsetHeight * -1}px 0px ${
        (window.innerHeight - header.offsetHeight - 1) * -1
      }px 0px`,
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const section = entry.target;
          const sectionColor = section.dataset.color || "true";
          $navbarColor = sectionColor ? sectionColor === "true" : false;
          themeSwitcher($navbarColor ? `#F8FAFC` : `#1E313B`);
        }
      });
    }, options);

    document.querySelectorAll("section").forEach((section) => {
      observer.observe(section);
    });
    loaded = true;
    navbarOpen.set(false);
  });
</script>

<svelte:window bind:innerWidth />

<header
  class:loaded
  class={`sticky top-0 z-50 max-h-screen overflow-y-scroll flex w-full flex-wrap ${bg} pt-3 text-sm md:flex-nowrap md:justify-center md:pt-4 md:text-xs xl:text-sm transition-colors`}
>
  <nav
    class="mx-4 lg:mx-8 w-full max-w-[85rem] border-b-[1px] border-slate-400 pb-3 md:flex md:items-center md:justify-between"
    aria-label="Global"
  >
    <div class={`flex items-center justify-between sticky top-0 ${($navbarOpen & loaded)? bg : ''}`}>
      <a
        class={`logo block flex-none relative -left-2 md:left-0 ${
          !$navbarColor ? "white-logo" : "color-logo"
        }`}
        href="/"
        rel="prefetch"
      >
        <LogoColor
          alt="American Medical Hearing Centers Logo"
          aria-label="Redirects to American Medical Hearing Center Homepage"
          height="70"
          width="234"
          class="logo-color"
        />
        <LogoWhite
          alt="American Medical Hearing Centers Logo"
          aria-label="Redirects to American Medical Hearing Center Homepage"
          height="70"
          width="234"
          class="logo-white"
        />
      </a>
      <div class="md:hidden">
        <button
          type="button"
          class={`inline-flex items-center justify-center gap-2 rounded-md border p-2 align-middle text-sm font-medium shadow-sm transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 navToggleColor ${navToggleColor}}`}
          data-hs-collapse="#navbar-image-1"
          aria-controls="navbar-image-1"
          aria-label="Toggle navigation"
          on:click={() => navbarOpen.set(!$navbarOpen)}
        >
          {#if $navbarOpen && loaded}
            <svg
              class="h-4 w-4"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          {:else}
            <svg
              class="h-4 w-4"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          {/if}
        </button>
      </div>
    </div>
    {#if $navbarOpen || innerWidth >= 768}
      <div
        id="navbar-image-1"
        class={`nav-container ${!loaded && "hidden"} grow justify-end items-center overflow-hidden md:flex`}
        transition:slide={{
          delay: 0,
          duration: 300,
          easing: quintOut,
          axis: "y",
        }}
      >
        <div
          class="mt-5 nav-links-container h-min flex flex-col gap-5 py-4 md:mt-0 md:flex-row md:flex-wrap md:items-center md:justify-end md:px-5 md:py-0"
        >
          {#each navigation as item}
            {#if item.uid == "services"}
              <DropdownNavItem
                name="Services"
                slug="service"
                openStore={servicesOpen}
                {currentPage}
                {item}
                {linkCol}
                {linkColCurr}
              />
            {:else if item.uid == "more"}
              <DropdownNavItem
                name="More"
                slug="more"
                openStore={moreOpen}
                {currentPage}
                {item}
                {linkCol}
                {linkColCurr}
              />
            {:else if currentPage == asText(item.data.nav[0].primary.link)}
              <a
                rel="prefetch"
                class={`font-medium linkColCurr ${linkColCurr}`}
                href={asText(item.data.nav[0].primary.link)}
                aria-current="page"
              >
                {asText(item.data.nav[0].primary.label)}
              </a>
            {:else}
              <a
                rel="prefetch"
                class={`font-medium linkCol ${linkCol}`}
                href={asText(item.data.nav[0].primary.link)}
              >
                {asText(item.data.nav[0].primary.label)}
              </a>
            {/if}
          {/each}
        </div>
        <div
          class="nav-buttons flex gap-4 whitespace-nowrap md:py-2 md:pl-5 md:pr-2 border-slate-400 md:border-l-[1px]"
        >
          <a
            rel="prefetch"
            href="/store-locator"
            class="inline-flex cursor-pointer items-center justify-center gap-2 rounded-md border bg-blue-100 px-3 py-3 align-middle text-sm font-medium text-blue-600 hover:text-blue-700 shadow-sm transition-all hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 focus:ring-offset-white md:text-xs lg:text-sm"
          >
            <span
              class="inline-flex items-center justify-center rounded-full text-blue-600 hover:text-blue-700"
            >
              <iconify-icon
                class="text-[1rem] w-4 h-4"
                icon={"uil:clinic-medical"}
              />
            </span>
            Find A Clinic
          </a>
          <a
            rel="prefetch"
            href="/schedule-an-appointment"
            class="inline-flex cursor-pointer items-center justify-center gap-2 rounded-md border border-transparent bg-blue-500 px-3 py-3 text-sm font-semibold text-white transition-all hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 md:text-xs lg:text-sm"
          >
            <span
              class="inline-flex items-center justify-center rounded-full text-white"
            >
              <iconify-icon
                class="text-[1rem] w-4 h-4"
                icon={"mdi:calendar-outline"}
              />
            </span>
            Schedule Appointment
          </a>
        </div>
      </div>
    {/if}
  </nav>
</header>

<style lang="scss">
  @media (min-width: theme("screens.md")) and (max-width: 900px) {
    .nav-buttons {
      flex-wrap: wrap;
      width: min-content;

      a {
        width: 100%;
      }
    }
  }

  @media (max-width: theme("screens.2xs")) {
    .nav-buttons {
      @apply flex-wrap gap-2;

      a {
        width: 100%;
      }
    }
  }
</style>
